import React from "react";

import BackgroundImage from "gatsby-background-image";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BackgroundSection = ({ data, className, children }) => {
  // Set ImageData.
  const imageData = data.heroimage.childImageSharp.fluid;

  return (
    <BackgroundImage Tag="div" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  );
};
const ThankYou = ({data}) => {
  return (
    <Layout
      headerClass="relative bg-white"
      bodyClass="px-0 md:px-0 lg:px-0 h-full"
    >
      <SEO title="Angela Cooney-Executive Coaching-Dublin-Ireland" />
      <div className="min-h-screen h-full flex flex-col mb-64 sm:mb-20 md:mb-20 ">
        <BackgroundSection data={data} className="w-full h-40 sm:h-32 lg:h-56">
          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="text-4xl md:text-3xl lg:text-5xl text-pink-600 leading-tight text-shadow-xl">
                Thank you for your enquiry
              </h1>
            </div>
          </div>
        </BackgroundSection>
        <BackgroundImage
          Tag="div"
          fluid={data.bgImage.childImageSharp.fluid}
          className="container mx-auto px-4 mt-5 sm:px-2 md:px-20 lg:px-24 w-full flex flex-col md:flex-row"
        >
          <div
            className="w-full sm:w-3/4 lg:w-1/2 mt-10 px-6 py-4 bg-white"
            style={{
              boxShadow:
                "0 15px 35px rgb(35,78,82,.1), 0 5px 15px rgba(0,0,0,.07)",
            }}
          >
            <p className="text-3xl">
              Thank you for your enquiry. <br /> I'll be in contact soon, Angela.
            </p>
          </div>
        </BackgroundImage>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroimage: file(relativePath: { eq: "contact-page-network-color.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgImage: file(
      relativePath: { eq: "curves-of-modern-architechture-black-and-white.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
export default ThankYou;
